
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-account",
  components: {},
  created() {
    const store = useStore();
    store.dispatch(Actions.GET_SUBSCRIBER, {    
      id: store.getters.currentUser.user._id,
    });
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const courses  = ref();
    setCurrentPageBreadcrumbs(t("account"), []);

    onMounted(() => {
      if (!store.getters.getAllCurses) {
        store.dispatch(Actions.CURSES_GET_ALL);
      }
    });

    const actualUserData = computed(() => {
      return {
        fname: store.getters.subscriberInfo?.fname ? store.getters.subscriberInfo?.fname : '',
        lname: store.getters.subscriberInfo?.lname ? store.getters.subscriberInfo?.lname : '',
        email: store.getters.subscriberInfo?.email ? store.getters.subscriberInfo?.email : '',
        phone: store.getters.subscriberInfo?.phone ? store.getters.subscriberInfo?.phone : '',
        city: store.getters.subscriberInfo?.city ? store.getters.subscriberInfo?.city : '',
        country: store.getters.subscriberInfo?.country ? store.getters.subscriberInfo?.country : ''
      };
    });

    const subscriberData = computed(() => {
      return store.getters.subscriberInfo ? store.getters.subscriberInfo : null;
    });

    const fullName = computed(() => {
      return actualUserData.value['fname'] + " " + actualUserData.value['lname']
    });

    const approve = computed(() => {
      return store.getters.subscriberInfo.approve
        ? store.getters.subscriberInfo.approve
        : "-";
    });

    const subEmail = computed(() => {
      return actualUserData.value['email']
        ? actualUserData.value['email']
        : "-";
    });

    const subSubscriptions = computed(() => {
      return store.getters.subscriberInfo.subscriptions
        ? store.getters.subscriberInfo.subscriptions.length
        : "-";
    });

    return {
      subscriberData,
      actualUserData,
      fullName,
      approve,
      subEmail,
      subSubscriptions,
      t,
    };
  },
});
